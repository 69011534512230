export const companyFilters = [
    {
        text: "Select Company",
        id: 0,
    },
    {
      text: "Google",
      id: 1,
    },
    {
      text: "Uber",
      id: 2,
    },
    {
      text: "Microsoft",
      id: 3,
    },
    {
      text: "Amazon",
      id: 4,
    },
    {
      text: "Razorpay",
      id: 5,
    },
  ];
  