export const selectedProfilesFilters = [
    {
      text: "All Profiles",
      id: 1,
    },
    {
      text: "SDE Roles",
      id: 2,
    },
    {
      text: "UX Developers",
      id: 3,
    },
    {
      text: "Indian StartUps",
      id: 4,
    },
    {
      text: "Remote",
      id: 4,
    },
  ];
  