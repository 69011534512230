import React from 'react'
import {useEffect} from 'react'
import styled from 'styled-components'

const PrivacyPolicies = () => {
  useEffect(() => {
    document.title = "Privacy Policies | Organisation Information - Algolisted";
}, []);
  return (
    <Container>
      {/* The below classes are being used from the parent */}
      <h1 className="main-heading">Privacy Policies</h1>
      <p className="simple-text">Effective date: 2022-12-01</p>
      <ol>
        <li>
          <div className="sub-heading-local">Introduction</div>
          <p className="simple-text">
            Welcome to <span>Algolisted</span>. Algolisted (“us”, “we”, or
            “our”) operates{" "}<span>algolisted.com</span> (hereinafter
            referred to as <span>“Service”</span>).
          </p>
          <p className="simple-text">
            Our Privacy Policy governs your visit to
            {" "}<span>algolisted.com</span>, and explains how we collect,
            safeguard and disclose information that results from your use of our
            Service.
          </p>
          <p className="simple-text">
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
          <p className="simple-text">
            Our Terms and Conditions (<span>“Terms”</span>) govern all use of
            our Service and together with the Privacy Policy constitutes your
            agreement with us (<span>“agreement”</span>).
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Definitions</div>
          <p className="simple-text">
            <span>SERVICE</span> means the algolisted.com website
            operated by Algolisted.
          </p>
          <p className="simple-text">
            <span>PERSONAL DATA</span> means data about a living individual who
            can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </p>
          <p className="simple-text">
            <span>USAGE DATA</span> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
          </p>
          <p className="simple-text">
            <span>COOKIES</span> are small files stored on your device (computer
            or mobile device).
          </p>
          <p className="simple-text">
            <span>DATA CONTROLLER</span> means a natural or legal person who
            (either alone or jointly or in common with other persons) determines
            the purposes for which and the manner in which any personal data
            are, or are to be, processed. For the purpose of this Privacy
            Policy, we are a Data Controller of your data.
          </p>
          <p className="simple-text">
            <span>DATA PROCESSORS</span> (OR SERVICE PROVIDERS) means any
            natural or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively.
          </p>
          <p className="simple-text">
            <span>DATA SUBJECT</span> is any living individual who is the
            subject of Personal Data. THE USER is the individual using our
            Service.
          </p>
          <p className="simple-text">
            <span>THE USER</span> corresponds to the Data Subject, who is the
            subject of Personal Data.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Types of Data Collected</div>
          <ol>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>Personal Data</span>
              </p>
              <p className="simple-text">
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (<span>“Personal Data”</span>).
                Personally identifiable information may include, but is not
                limited to:
              </p>
              <ol>
                <li className="text-indent-2 simple-text">
                  Email address
                </li>
                <li className="text-indent-2 simple-text">
                  First name and last name
                </li>
                <li className="text-indent-2 simple-text">
                  Phone number
                </li>
                <li className="text-indent-2 simple-text">
                  Address, Country, State, Province, ZIP/Postal code, City
                </li>
                <li className="text-indent-2 simple-text">
                  Cookies and Usage Data
                </li>
              </ol>
              <p className="simple-text">
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link.
              </p>
            </li>
            <br></br>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>Usage Data</span>
              </p>

              <p className="simple-text">
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through
                any device (<span>“Usage Data”</span>).
              </p>

              <p className="simple-text">
                This Usage Data may include information such as your computer’s
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p className="simple-text">
                When you access Service with a device, this Usage Data may
                include information such as the type of device you use, your
                device unique ID, the IP address of your device, your device
                operating system, the type of Internet browser you use, unique
                device identifiers and other diagnostic data.
              </p>
            </li>
            <br></br>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>Tracking Cookies Data</span>
              </p>
              <p className="simple-text">
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>
              <p className="simple-text">
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>
              <p className="simple-text">
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p className="simple-text">Examples of Cookies we use:</p>
              <ol>
                <li className="text-indent-2 simple-text">
                  <span>Session Cookies:</span> We use Session Cookies to
                  operate our Service
                </li>
                <li className="text-indent-2 simple-text">
                  <span>Preference Cookies:</span> We use Preference Cookies to
                  remember your preferences and various settings.
                </li>
                <li className="text-indent-2 simple-text">
                  <span>Security Cookies:</span> We use Security Cookies for
                  security purposes.
                </li>
              </ol>
            </li>
            <br></br>
          </ol>
        </li>
        <li>
          <div className="sub-heading-local">Information Collection and Use</div>
          <p className="simple-text">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">
            <span>Use of Data</span>
          </div>
          <ol>
            <li className="text-indent-1 simple-text">
              to provide and maintain our Service;
            </li>
            <li className="text-indent-1 simple-text">
              to notify you about changes to our Service;
            </li>
            <li className="text-indent-1 simple-text">
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </li>
            <li className="text-indent-1 simple-text">
              to provide customer support;
            </li>
            <li className="text-indent-1 simple-text">
              to gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li className="text-indent-1 simple-text">
              to monitor the usage of our Service;
            </li>
            <li className="text-indent-1 simple-text">
              to detect, prevent and address technical issues;
            </li>
            <li className="text-indent-1 simple-text">
              to fulfil any other purpose for which you provide it;
            </li>
            <li className="text-indent-1 simple-text">
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li className="text-indent-1 simple-text">
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li className="text-indent-1 simple-text">
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </li>
            <li className="text-indent-1 simple-text">
              in any other way we may describe when you provide the information;
            </li>
            <li className="text-indent-1 simple-text">
              for any other purpose with your consent.
            </li>
          </ol>
        </li>
        <li>
          <div className="sub-heading-local">Retention of Data</div>
          <p className="simple-text">
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p className="simple-text">
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Disclosure of Data</div>
          <p className="simple-text">
            We do not disclose personal information that we collect, or you
            provide:
          </p>
        </li>
        <li>
          <div className="sub-heading-local"> Security of Data</div>
          <p className="simple-text">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR)
          </div>
          <p className="simple-text">
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR.
          </p>
          <p className="simple-text">
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p className="simple-text">
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at
            <span>
              <a href="mailto: nayak.primary@gmail.com">
                nayak.primary@gmail.com
              </a>
            </span>
            .
          </p>
          <p className="simple-text">
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ol>
            <li className="text-indent-1 simple-text">
              the right to access, update or to delete the information we have
              on you;
            </li>
            <li className="text-indent-1 simple-text">
              the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li className="text-indent-1 simple-text">
              the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li className="text-indent-1 simple-text">
              the right of restriction. You have the right to request that we
              restrict the processing of your personal information;
            </li>
            <li className="text-indent-1 simple-text">
              the right to data portability. You have the right to be provided
              with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </li>
            <li className="text-indent-1 simple-text">
              the right to withdraw consent. You also have the right to withdraw
              your consent at any time where we rely on your consent to process
              your personal information;
            </li>
          </ol>
          <p className="simple-text">
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p className="simple-text">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
        </li>
        <li>
          <div className="sub-heading-local">
            Your Data Protection Rights under the California Privacy Protection
            Act (CalOPPA)
          </div>
          <p className="simple-text">
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy.
          </p>
          <p className="simple-text">
            According to CalOPPA we agree to the following:
          </p>
          <ol>
            <li className="text-indent-1 simple-text">
              users can visit our site anonymously;
            </li>
            <li className="text-indent-1 simple-text">
              our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the home page of our website;
            </li>
            <li className="text-indent-1 simple-text">
              users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </li>
            <li className="text-indent-1 simple-text">
              users are able to change their personal information by emailing us
              at
              <span>
                <a href="mailto: nayak.primary@gmail.com">
                  nayak.primary@gmail.com
                </a>
              </span>
              .
            </li>
          </ol>
          <p className="simple-text">
            Our Policy on “Do Not Track” Signals:
          </p>
          <p className="simple-text">
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track browser mechanism is in place.
            Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
          </p>
          <p className="simple-text">
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">
            Your Data Protection Rights under the California Consumer Privacy
            Act (CCPA)
          </div>
          <p className="simple-text">
            If you are a California resident, you are entitled to learn what
            data we collect about you, ask to delete your data and not to sell
            (share) it. To exercise your data protection rights, you can make
            certain requests and ask us:
          </p>
          <ol>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>
                  What personal information we have about you. If you make this
                  request, we will return to you:
                </span>
              </p>
              <ol className="text-indent-2 simple-text">
                <li>
                  <p className="simple-text">
                    The categories of personal information we have collected
                    about you.
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    The categories of sources from which we collect your
                    personal information.
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    The business or commercial purpose for collecting or selling
                    your personal information
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    The specific pieces of personal information we have
                    collected about you
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    A list of categories of personal information that we have
                    sold, along with the category of any other company we sold
                    it to. If we have not sold your personal information, we
                    will inform you of that fact.
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    A list of categories of personal information that we have
                    disclosed for a business purpose, along with the category of
                    any other company we shared it with.
                  </p>
                </li>
                <li>
                  <p className="simple-text">
                    The categories of third parties with whom we share personal
                    information.
                  </p>
                </li>
              </ol>
            </li>

            <p className="simple-text">
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous 12
              months.
            </p>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>
                  To delete your personal information. If you make this request,
                  we will delete the personal information we hold about you as
                  of the date of your request from our records and direct any
                  service providers to do the same. In some cases, deletion may
                  be accomplished through de-identification of the information.
                  If you choose to delete your personal information, you may not
                  be able to use certain functions that require your personal
                  information to operate.
                </span>
              </p>
            </li>
            <li className="text-indent-1">
              <p className="simple-text">
                <span>
                  To stop selling your personal information. We don’t sell or
                  rent your personal information to any third parties for any
                  purpose. We do not sell your personal information for monetary
                  consideration. However, under some circumstances, a transfer
                  of personal information to a third party, or within our family
                  of companies, without monetary consideration may be considered
                  a “sale” under California law. You are the only owner of your
                  Personal Data and can request disclosure or deletion at
                  anytime.
                </span>
              </p>
            </li>
          </ol>
          <p className="simple-text">
            If you submit a request to stop selling your personal information,
            we will stop making such transfers.
          </p>
          <p className="simple-text">
            Please note, if you ask us to delete or stop selling your data, it
            may impact your experience with us, and you may not be able to
            participate in certain programs or membership services which require
            the usage of your personal information to function. But in no
            circumstances, we will discriminate against you for exercising your
            rights.
          </p>
          <p className="simple-text">
            To exercise your California data protection rights described above,
            please send your request(s) by email:{" "}
            <span>
              <a href="mailto: nayak.primary@gmail.com">
                nayak.primary@gmail.com
              </a>
            </span>
            .
          </p>
          <p className="simple-text">
            Your data protection rights, described above, are covered by the
            CCPA, short for the California Consumer Privacy Act. To find out
            more, visit the official California Legislative Information website.
            The CCPA took effect on 01/01/2020.
          </p>
        </li>
        <li>
          <div className="sub-heading-local"> Service Providers</div>
          <p className="simple-text">
            We may employ third party companies and individuals to facilitate
            our Service (<span>“Service Providers”</span>), provide Service on
            our behalf, perform Service-related services or assist us in
            analysing how our Service is used.
          </p>
          <p className="simple-text">
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Analytics</div>
          <p className="simple-text">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">CI/CD tools</div>
          <p className="simple-text">
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Links to Other Sites</div>
          <p className="simple-text">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p className="simple-text">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Changes to This Privacy Policy</div>
          <p className="simple-text">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="simple-text">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p className="simple-text">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </li>
        <li>
          <div className="sub-heading-local">Contact Us</div>
          <p className="simple-text">
            If you have any questions about this Privacy Policy, please contact
            us by email:{" "}
            <span>
              <a href="mailto: nayak.primary@gmail.com">
                nayak.primary@gmail.com
              </a>
            </span>
          </p>
        </li>
      </ol>
    </Container>
  );
};

export default PrivacyPolicies;

const Container = styled.div`
  width: 100%;

  img {
    height: 300px;
    margin: 20px 0;
  }

  .simple-text{
    font-size: 0.85rem;
    font-weight: 200;
    line-height: 1.5rem;
    letter-spacing: 0.07rem;
    margin-bottom: 10px;
  }

  .sub-heading-local{
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    margin-top: 50px;
  }

  ul{
    font-weight: 300;
    font-size: 0.9rem;
  }

  span {
    font-weight: 500;
  }

  .text-indent-1 {
    margin-left: 25px;
  }
  
  .text-indent-2 {
    margin-left: 50px;
  }
`;